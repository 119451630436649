import React, {useEffect, useState} from 'react';
import style from "./style.module.scss";
import {WithChildren} from "../../interfaces/common";

function useCycler(maxNumber: number): [number, () => void] {
    const [number, setNumber] = useState(0);

    const nextNumber = () => {
        setNumber(prevNumber => (prevNumber + 1) % maxNumber);
    };

    return [number, nextNumber];
}
function useArrayCycle<T>(array: T[]): [T, () => void] {
    const [currentIndex, nextCycle] = useCycler(array.length);

    return [array[currentIndex], nextCycle];
}

export default function Theme({ children }: WithChildren): JSX.Element {
    const [theme, nextCycle] = useArrayCycle(['light', 'dark']);

    useEffect(() => {
        const onChangeThemeKey = (e: { key: string; }) => {
            if (e.key !== 't') {
                return;
            }
            if (document.querySelector(':focus')) {
                return;
            }
            nextCycle();
        };
        document.addEventListener('keydown', onChangeThemeKey);

        return () => {
            document.removeEventListener('keydown', onChangeThemeKey);
        };
    }, [theme, nextCycle]);

    return (
        <div className={style.theme} data-theme={theme}>
            {children}
        </div>
    );
}