import React, {useContext} from 'react';
import Pikoman from '@pikoman/ws';
export interface PikomanContextProps {
    state: Pikoman,
}
const { REACT_APP_WS_URL = '' } = process.env;
// @ts-ignore
const PikomanContext = React.createContext<PikomanContextProps>(null);

const PikomanProvider: React.FC = ({ children }): JSX.Element => {
    const pikoman = new Pikoman("app-1", {
        ws_address: REACT_APP_WS_URL,
        auth_url: '/api/ws-auth'
    });
    const value = {
      state: pikoman
    };
    return <PikomanContext.Provider value={value}>
        {children}
    </PikomanContext.Provider>
}

const usePikoman = (): PikomanContextProps => useContext(PikomanContext);

export { PikomanContext, PikomanProvider, usePikoman }