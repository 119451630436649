import React, {Suspense} from 'react';
import { Route, Switch, HashRouter as Router } from 'react-router-dom';
import Theme from "./components/Theme";
import Contexts from "./contexts";
const HomePage = React.lazy(() => import('./pages/Home'));
const SolutionsPage = React.lazy(() => import('./pages/Solutions'));
const ExamplesPage = React.lazy(() => import('./pages/Examples'));

function Routing() {
    return <Router>
        <Switch>
            <Route path={"/Examples/:example"}>
                <ExamplesPage />
            </Route>
            <Route path="/Solutions/:service?">
                <SolutionsPage />
            </Route>
            <Route path="/">
                <HomePage />
            </Route>
        </Switch>
    </Router>
}

function App() {
    return (
        <Contexts>
            <Theme>
                <Suspense fallback={null}>
                    <Routing />
                </Suspense>
            </Theme>
        </Contexts>
    );
}

export default App;
