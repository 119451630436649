import React from "react";
import {PikomanProvider} from "./pikoman";

const Contexts: React.FC = ({ children }): JSX.Element => {
    return (
        <PikomanProvider>
            {children}
        </PikomanProvider>
    );
};

export default Contexts;